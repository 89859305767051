import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import Image from "../components/image"
import { Link } from "gatsby"

import Grid from '@mui/material/Grid';

const artists = [
  {
    name: "Alex Rapp",
    linkName: "Alex",
    picture: "alex.jpg"
  },
  {
    name: "Denis Granan",
    linkName: "Denis",
    picture: "denis.jpg"
  },
  {
    name: "D'aria",
    linkName: "Daria",
    picture: "daria.jpg"
  },
  {
    name: "Rabbit Hole",
    linkName: "RabbitHole",
    picture: "rabbithole"
  },
  {
    name: "Rumia",
    linkName: "Rumia",
    picture: "rumia-album.jpg"
  },
]

const renderedArtists = artists.map((item, _) => (
  <Grid item xs={12} md={4}>
    <Link to={"/artists/" + item.linkName} style={{textDecoration: 'none', color: 'black'}}>
      <Image filename={item.picture} alt={item.name}></Image>
      <h4 style={{textAlign: 'center', marginTop: '1rem', marginBottom: '1rem'}}>
        {item.name.toUpperCase()}
      </h4>
    </Link>
  </Grid>
))

const ArtistsPage = () => (
  <Layout>
    <Seo title="Our Artists" />
    <Grid container spacing={4} justifyContent="center">
      {renderedArtists}
    </Grid>
  </Layout>
)

export default ArtistsPage
